var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm text-right pb-16px pb-md-20px"
  }, [_c('span', {
    staticClass: "red--text"
  }, [_vm._v("* ")]), _vm._v("필수입력 항목입니다.")]), _c('vertical-form-table', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false), [_c('template', {
    slot: "비밀번호 확인"
  }, [_c('v-password-confirm-field', {
    attrs: {
      "password": _vm.form.password,
      "outlined": ""
    }
  })], 1), _c('template', {
    slot: "성별"
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.gender,
      callback: function ($$v) {
        _vm.$set(_vm.form, "gender", $$v);
      },
      expression: "form.gender"
    }
  }, [_c('v-radio', _vm._b({
    attrs: {
      "label": "남성",
      "value": "MALE"
    }
  }, 'v-radio', Object.assign({}, _vm.$attrs), false)), _c('v-radio', _vm._b({
    attrs: {
      "label": "여성",
      "value": "FEMALE"
    }
  }, 'v-radio', Object.assign({}, _vm.$attrs), false))], 1)], 1)], 2), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "tile": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('save');
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.loading, {
    disabled: _vm.disabled
  }), false), [_vm._v("가입완료")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }