<template>
    <v-form v-model="isValid">
        <div class="txt txt--sm text-right pb-16px pb-md-20px"><span class="red--text">* </span>필수입력 항목입니다.</div>

        <vertical-form-table v-model="form" v-bind="{ items }" @input="emit">
            <template slot="비밀번호 확인">
                <v-password-confirm-field :password="form.password" outlined />
            </template>
            <template slot="성별">
                <v-radio-group v-model="form.gender" row hide-details @change="emit">
                    <v-radio v-bind="{...$attrs }" label="남성" value="MALE" />
                    <v-radio v-bind="{...$attrs }" label="여성" value="FEMALE"/>
                </v-radio-group>
            </template>
        </vertical-form-table>

        <div class="btn-wrap btn-wrap--lg">
            <v-row justify="center" class="row--sm">
                <v-col cols="6" sm="auto">
                    <v-btn tile v-bind="{ ...btn_primary, ...loading, disabled }" class="v-btn--width-fixed v-size--xx-large w-100 min-w-sm-200px" @click="$emit('save')">가입완료</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-form>
</template>

<script>
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";
import { attrs_input, btn_primary, initUser, rules } from "@/assets/variables";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
const items = [
    {
        key: "username",
        term: "아이디",
        type: "text",
        rules: rules.username,
        required: true,
        placeholder: "공백 없는 영문/숫자 포함 6~20자",
        ...attrs_input,
    },
    {
        key: "password",
        term: "비밀번호",
        type: "password",
        rules: rules.password,
        required: true,
        ...attrs_input,
    },
    {
        key: "password",
        term: "비밀번호 확인",
        required: true,
        ...attrs_input,
    },
    {
        key: "name",
        term: "이름",
        type: "text",
        rules: rules.name,
        required: true,
        disabled: true,
        ...attrs_input,
    },
    {
        key: "email",
        term: "이메일",
        type: "email",
        rules: rules.email,
        required: true,
        ...attrs_input,
    },
    {
        key: "phone",
        term: "휴대폰 번호",
        type: "phone",
        // rules: rules.phone,
        required: true,
        disabled: true,
        ...attrs_input,
    },
    {
        key: "address",
        term: "주소",
        type: "address",
        // rules: rules.address,
        required: true,
        ...attrs_input,
    },
    {
        key: "nickname",
        term: "닉네임",
        type: "text",
        rules: rules.nickname,
        // placeholder: "내용을 입력하세요.",
        required: true,
        ...attrs_input,
    },
    {
        key: "birthday",
        term: "생년일일",
        type: "date",
        // rules: rules.birthday,
        required: true,
        disabled: true,
        ...attrs_input,
    },
    {
        key: "gender",
        term: "성별",
        required: true,
        rules: rules.gender,
        ...attrs_input
    },
];

export default {
    components: {
        VerticalFormTable,
        VPasswordConfirmField
    },
    props: {
        value: { type: Object, default: initUser },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initUser(),

        items,
        btn_primary,
        isValid: false,
    }),
    computed: {
        disabled() {
            return !this.isValid;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initUser(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>
